<template>
  <div>
    <GenericTable
      ref="lista"
      name="material"
      title="Materiais"
      add-route="materialAdicionar"
      edit-route="materialEditar,materialMedicoEditar"
      rastreabilidadeRoute="materialRastrear"
      :cols="[
        'ID',
        'Material',
        'Unidade',
        'Tipo de Material',
        'Quantidade de Itens',
      ]"
      :cols-name="[
        'id',
        'descricao',
        'unidadePatrimonio',
        'tipo_material',
        'quantidade_itens',
      ]"
      :cols-map="
        (i) => [
          i.shownId,
          i.descricao,
          i.unidadePatrimonio.nome,
          i.tipoMaterial.nome,
          i.quantidade_itens,
        ]
      "
      :route="materialRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filters"
      @clear-filters="clearFilters"
      :editRouteIndex="editRouteIndex"
      idKey="id_material"
      :hasPagination="true"
      :noadd="noAdd"
      :disabledInputs="disabledInputs"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_descricao"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>QR Code</label>
              <b-form-input
                v-model="filters.code"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_code"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>ID</label>
              <b-form-input
                v-model="filters.id"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_id_material"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Tipo de Material</label>
              <GenericSelect
                name="tipoMaterial"
                labelKey="nome"
                v-model="filters.id_tipo_material"
                route="tipoMaterial"
                ref="filter_tipo_material"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <b-form-select
                name="unidade"
                labelKey="nome"
                v-model="filters.id_unidade"
                :options="optionsUnidade"
                :disabled="!hasBlocoOrCmeOrOpme"
                ref="filter_id_unidade"
              >
              <template slot="first">
                <b-form-select-option :value="null">
                  -- Por favor, selecione uma opção --
                </b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Fornecedor</label>
              <GenericSelect
                name="fornecedor"
                labelKey="nome"
                v-model="filters.id_fornecedor"
                route="fornecedor"
                ref="filter_fornecedor"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
    <Modal
      id="modalEtiquetaMaterial"
      ref="modalEtiquetaMaterial"
      name="modalEtiquetaMaterial"
      key="modalEtiquetaMaterial"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hidden="closingModal"
      :hideXClose="handlerXButton"
    >
      <Printer
        :resultData="itensToPrint"
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
        :isMaterialTag="true"
        @successSend="successZPL"
        @wants-exit="wantsExit"
        @show-xclose="handleXButton"
        :indexMaterial="indexMaterial"
      >
        <template #printContent class="w">
          <EtiquetaMaterial
            :relatorioData="itensToPrint"
            :indexMaterial="indexMaterial"
          />
        </template>

      </Printer>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import genericRequest from '@/services/genericRequest';
import GenericSelect from '@/components/Form/GenericSelect';
import Modal from '@/components/Utils/Modal';
import Printer from '@/components/Utils/Printer';
import unidadeServices from '../../services/unidade';
import loginServices from '../../services/login';
import EtiquetaMaterial from './Etiqueta';

export default {
  components: {
    GenericSelect,
    Modal,
    Printer,
    EtiquetaMaterial,
  },
  data() {
    return {
      materialRoute: 'material',
      filters: {
        descricao: '',
        code: '',
        id: '',
        id_tipo_material: null,
        id_unidade: null,
        id_fornecedor: null,
      },
      optionsUnidade: [
        { value: -1, text: 'Rede' },
      ],
      hasBlocoOrCmeOrOpme: false,
      noAdd: false,
      materiaisDoMesmoLote: [],
      itensToPrint: {},
      indexMaterial: 0,
      zplSuccessPrinter: false,
      exit: false,
      handlerXButton: true,
      disabledInputs: [],
    };
  },
  computed: {
    ...mapState(['previousRoute', 'filtersStored', 'currentUnidade']),
  },
  async created() {
    const user = loginServices.getUser();
    this.hasBlocoOrCmeOrOpme = user.hasBloco || user.hasCme || user.areas.includes('opme');
    if (!this.hasBlocoOrCmeOrOpme) {
      this.filters.id_unidade = this.currentUnidade.id_unidade;
      this.noAdd = true;
    }

    if (this.filtersStored.material) {
      if ([
        'materialRastrear', 'materialEditar', 'materialMedicoEditar',
      ].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.material };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }

    const unidades = await unidadeServices.getUnidades();
    this.optionsUnidade = this.optionsUnidade.concat(unidades.map((unid) => ({
      value: unid.id_unidade,
      text: unid.nome,
    })));
  },
  async mounted() {
    this.setDisabledInputs();
    if (this.$route.query && this.$route.query.cadastroEmLote) {
      this.materiaisDoMesmoLote.materiais = await genericRequest.getWithoutPagination(
        {},
        `material/materiaisPorLote/${this.$route.query.id_lote_material}`,
      );

      this.itensToPrint = this.materiaisDoMesmoLote.materiais;

      this.$refs.modalEtiquetaMaterial.show();
    }
  },
  methods: {
    setDisabledInputs() {
      const filterInputKeys = Object.keys(this.$refs).filter((key) => key.includes('filter_'));
      if (filterInputKeys) {
        const refs = this.$refs;
        const filterInputs = filterInputKeys.map((key) => {
          const input = refs[key];
          return { name: key.substr(7), input };
        });
        const disabledInputs = filterInputs.map((element) => element.input.disabled && element)
          .filter((input) => typeof input !== 'boolean');
        this.disabledInputs = disabledInputs;
        return disabledInputs;
      }
      this.disabledInputs = [];
      return [];
    },
    // caso tipo do material seja OPME Consignado ou OPME Comodato entao a rota de edit é a terceira [2]
    // caso exista medico cadastrado envia para segunda rota [1] em edit, a rota de material medico
    // TODO Hardcoded tipo material
    editRouteIndex(material) {
      if (['OPME Consignado'].includes(material.tipoMaterial.nome)) return 2;
      return material.id_medico ? 1 : 0;
    },
    clearFilters() {
      if (!this.hasBlocoOrCmeOrOpme) {
        this.filters.descricao = '';
        this.filters.code = '';
        this.filters.id = '';
        this.filters.id_tipo_material = null;
        this.filters.id_fornecedor = null;
      } else {
        this.filters.descricao = '';
        this.filters.code = '';
        this.filters.id = '';
        this.filters.id_tipo_material = null;
        this.filters.id_unidade = null;
        this.filters.id_fornecedor = null;
      }
    },
    successZPL() {
      this.zplSuccessPrinter = true;
      this.indexMaterial = 0;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    closingModal() {
      if (
        this.itensToPrint
        && this.itensToPrint.materiais
        && this.itensToPrint.materiais[this.indexMaterial + 1]
        && !this.zplSuccessPrinter
        && !this.exit
      ) {
        this.indexMaterial++;
        this.$refs.modalEtiquetaMaterial.show();
      } else {
        this.exit = false;
        this.indexMaterial = 0;
        this.zplSuccessPrinter = false;
        this.$refs.modalEtiquetaMaterial.hide();
        this.$router.replace({
          name: 'material',
        });
        this.handlerXButton = true;
      }
    },
    wantsExit() {
      this.exit = true;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    handleXButton() {
      this.handlerXButton = !this.handlerXButton;
    },
  },
};
</script>
